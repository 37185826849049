import { Injectable } from '@angular/core';

import { AppVersionService } from '@ib-core/services/app-version.service';
import { AuthFacadeService } from '@ib-core-services/auth-facade.service';
import { LOCAL_STORAGE_KEYS } from '@ib-enums/local-storage-keys.enum';
import { IAuthUser } from '@ib-shared/interfaces/auth-user.intf';

import { LocalStorageService } from './core/services/local-storage.service';

@Injectable()
export class AppInitializerService {
  constructor(
    private appVersionS: AppVersionService,
    private localStorageS: LocalStorageService,
    private authFacadeS: AuthFacadeService,
  ) {}
  init(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.appVersionS.checkVersion();
      this.initAuthUserFromLocalStorage();
      resolve(true);
    });
  }

  private initAuthUserFromLocalStorage(): void {
    // todo - some auth 3rd party logica ???
    this.authFacadeS.setAuthUser(
      (this.localStorageS.get(LOCAL_STORAGE_KEYS.AUTH_USER) as IAuthUser) ||
        null ||
        null,
    );
    const at = this.localStorageS.get(
      LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
    ) as string;
    const rt = this.localStorageS.get(
      LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
    ) as string;
    if (at) {
      this.authFacadeS.setAccessToken(at);
    }
    if (rt) {
      this.authFacadeS.setRefreshToken(rt);
    }
  }
}

export function initializeApp(appInit: AppInitializerService) {
  return (): Promise<boolean> => {
    return appInit.init();
  };
}
