
export enum INVESTOR_SAVING_FOR {
  MONTH = 'month',
  QUARTER = 'quarter',
  OCCASSIONAL = 'occassional',
  LUMP_SUM_INVEST = 'lump_sum_investment',
	SHORT_TERM_DEPOSIT_WITHDRAW = 'short_term_deposits_withdraw', // company
	SHORT_TERM_VAT3_CT1 = 'short_term_vat3_ct1', // company
	REGULAR_SAVING_UNUSED_CASH = 'regular_saving_unused_cash', // company
	LONG_TERM_SAVINGS = 'long_term_savings', // company
	OTHER = 'other' // company
}

export enum INVESTOR_WHAT_FOR {
  HOUSE = 'house',
  UNEXPECTED = 'unexpected',
  RAINY_DAY = 'rainy_day',
  TRAVEL = 'travel',
  GOALS = 'goals',
  RETIREMENT = 'retirement',
  OTHER = 'other',
}
