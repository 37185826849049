import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ISnackbarData } from '@ib-shared/interfaces/snackbar-data.intf';
import { IbSnackbarComponent } from '@ib-shared/modules/snackbar/snackbar.component';
import { TranslocoPipe } from '@ngneat/transloco';
import { SERVER_CUSTOM_ERRORS } from 'credebt-shared';

import { UiTouchscreenService } from './ui-touchscreen.service';

@Injectable({
  providedIn: 'root',
})
export class MatSnackBarService {
  private httpErrExeptions: Array<SERVER_CUSTOM_ERRORS> = [
    SERVER_CUSTOM_ERRORS.WRONG_SECURE_CODE,
  ];
  constructor(
    private matSnackBar: MatSnackBar,
    private tsS: UiTouchscreenService,
    private translate: TranslocoPipe,
  ) {}

  handleHttpError(err: HttpErrorResponse): void {
    if (
      this.httpErrExeptions.includes(err?.error?.message || err?.message || '')
    ) {
      return;
    }
    let uiMsg = '';
    const customMsg = err?.error?.message;
    if (customMsg) {
      if (typeof customMsg === 'string') {
        // check if it is a key. defined in BE like keys for translate it on FE
        if (customMsg.startsWith('SCE__')) {
          uiMsg = this.translate.transform('sce.' + customMsg);
          // show direct msg from BE
        } else {
          uiMsg = err.message;
        }
      } else if (Array.isArray(customMsg)) {
        customMsg.forEach(m => {
          uiMsg += m + ', ';
        });
      }
    } else {
      // unexpected error
      if (err.status === 500) {
        uiMsg =
          '500. ' +
          this.translate.transform('base.err-500') +
          '. ' +
          this.translate.transform('base.try-later');
      } else {
        const msg = err?.message;
        if (Array.isArray(msg)) {
          // list of requirements for 404
          msg.forEach(m => {
            uiMsg += m + ', ';
          });
        } else {
          // 400 404 - handle here ???
          // uiMsg = msg;
        }
      }
    }
    if (uiMsg) {
      this.show({
        msg: uiMsg,
        type: 'error',
        durationMs: uiMsg.length * 50,
      });
    }
  }

  // todo - think about time
  show(data: ISnackbarData): void {
    const defDuration = 5000;
    const duration = data.durationMs
      ? data.durationMs < defDuration
        ? defDuration
        : data.durationMs
      : data.type === 'error'
      ? defDuration
      : defDuration;

    this.matSnackBar.openFromComponent(IbSnackbarComponent, {
      data: data,
      duration: duration,
      panelClass: ['ib-snackbar-' + data.type || 'info'],
      verticalPosition: this.tsS.isTouchScreen ? 'top' : 'top',
      horizontalPosition: this.tsS.isTouchScreen ? 'center' : 'right',
    });
  }
}
