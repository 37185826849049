import { Injectable } from '@angular/core';

import { IAuthUser } from '@ib-shared/interfaces/auth-user.intf';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  public user$ = new BehaviorSubject<IAuthUser | null>(null);
  public registrationDocsModalWasInited = false; // askk additional registr doc
  public accessToken: string;
  public refreshToken: string;
  public logout$ = new BehaviorSubject<boolean>(false);
}
