import { Injectable } from '@angular/core';

import {
  LOCAL_STORAGE_KEYS,
  LOCAL_STORAGE_KEYS_CONST_PREFIX,
  LOCAL_STORAGE_KEYS_PREFIX,
} from '@ib-enums/local-storage-keys.enum';
import { isEqual } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private storage = localStorage || sessionStorage;

  constructor() {
    // todo temp remove
    if (
      this.storage &&
      this.get(LOCAL_STORAGE_KEYS.APP_VERSION) === 'APP_VERSION'
    ) {
      this.storage.clear();
    }
  }

  set(key: LOCAL_STORAGE_KEYS, value: unknown): void {
    this.storage?.setItem(key, JSON.stringify(value));
  }

  get(key: LOCAL_STORAGE_KEYS): unknown {
    const v = this.storage?.getItem(key) || null;
    const parsed = v ? JSON.parse(v) : null;
    return parsed && !isEqual(parsed, {}) ? parsed : null;
  }

  remove(key: LOCAL_STORAGE_KEYS): void {
    this.storage?.removeItem(key);
  }

  clearAfterChangeVersion(): void {
    Object.keys(this?.storage || {}).forEach(key => {
      if (
        key.startsWith(LOCAL_STORAGE_KEYS_PREFIX) &&
        !key.startsWith(LOCAL_STORAGE_KEYS_CONST_PREFIX)
      ) {
        this.storage?.removeItem(key);
      }
    });
  }

  clearAll(): void {
    Object.keys(this?.storage || {}).forEach(key => {
      if (key.startsWith(LOCAL_STORAGE_KEYS_PREFIX)) {
        this.storage?.removeItem(key);
      }
    });
  }
}
