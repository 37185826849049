

export interface IPhoneConfig {
	SIX_DIGIT_CODE_VALID_DURING_SEC: number,// for prevent fraud ?? - amount
	MAX_SEND_CODE_ATTEMPTS: number,// for prevent fraud ?? - amount
	MAX_CONFIRM_CODE_ATTEMPTS: number,// for prevent fraud ?? - amount
	BLOCK_VALIDATION_AFTER_FAIL_SEC: number, // any check for current phone will be blocked for some time after (provided seconds amount after last failed attempt) reach MAX_SEND_CODE_ATTEMPTS MAX_CONFIRM_CODE_ATTEMPTS
	MAX_AMOUNT_OF_TOTAL_VALIDATION: number, //  any check for current phone will be blocked totally after reach limit of checks
}

const PHONE_CONFIG: IPhoneConfig = {
	SIX_DIGIT_CODE_VALID_DURING_SEC: 30, // 1 min
	MAX_SEND_CODE_ATTEMPTS: 10,
	MAX_CONFIRM_CODE_ATTEMPTS: 10,
	BLOCK_VALIDATION_AFTER_FAIL_SEC: 60 * 60, // 1 h
	MAX_AMOUNT_OF_TOTAL_VALIDATION: 100,
}

const PHONE_CONFIG_DEV: IPhoneConfig = {
	SIX_DIGIT_CODE_VALID_DURING_SEC: 30,
	MAX_SEND_CODE_ATTEMPTS: 4,
	MAX_CONFIRM_CODE_ATTEMPTS: 4,
	BLOCK_VALIDATION_AFTER_FAIL_SEC: 1 * 60,
	MAX_AMOUNT_OF_TOTAL_VALIDATION: 4,
}

export function getPhoneConfig(isProduction: boolean): IPhoneConfig {
	return isProduction ? PHONE_CONFIG : PHONE_CONFIG_DEV
}
