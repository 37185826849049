// !!! only with Prefix 'SCE__' - need for FE

 enum SERVER_CUSTOM_ERRORS {
	EMAIL_ALREADY_EXIST = 'SCE__EMAIL_ALREADY_EXIST',
	PHONE_NOT_AVAILABLE = 'SCE__PHONE_NOT_AVAILABLE',
	PHONE_REGIST_VALIDATE_PHONE_LOOKS_LIKE_FRAUD = 'SCE__PHONE_REGIST_VALIDATE_PHONE_LOOKS_LIKE_FRAUD', // block phone till use rnot connect with support
	PHONE_TOO_MANY_ATTEMPTS_SEND_CODE = 'SCE__PHONE_TOO_MANY_ATTEMPTS_SEND_CODE',
	PHONE_ALREADY_USED = 'SCE__PHONE_ALREADY_USED',
	PHONE_CODE_EXPIRED = 'SCE__PHONE_CODE_EXPIRED',
	WRONG_SECURE_CODE = 'SCE__WRONG_SECURE_CODE',
	PHONE_TOO_MANY_ATTEMPTS_CONFIRM_CODE = 'SCE__PHONE_TOO_MANY_ATTEMPTS_CONFIRM_CODE',
	PHONE_RECENT_ATTEMPT_TO_VALIDATE_PHONE = 'SCE__PHONE_RECENT_ATTEMPT_TO_VALIDATE_PHONE',
	PHONE_REGISTR_ERROR = 'SCE__PHONE_REGISTR_ERROR',
	LOGIN_FAILED = 'SCE__LOGIN_FAILED',
	RESET_PASSW_EMAIL_DONT_EXIST = 'SCE__RESET_PASSW_EMAIL_DONT_EXIST',
	RESET_PASSW_LINK_EXPIRED_OR_INVALID = "SCE__RESET_PASSW_LINK_EXPIRED_OR_INVALID",
	RESET_PASSW_CURR_PASSW_WAS_USED_BEFORE = "SCE__RESET_PASSW_CURR_PASSW_WAS_USED_BEFORE"
}

// check if name is correct
(() => {
	Object.values(SERVER_CUSTOM_ERRORS).forEach(name => {
		if (!name.startsWith('SCE__')) {
			throw new Error('Wrong name of error - name must starts from SCE__')
		}
	})
})()

export {SERVER_CUSTOM_ERRORS}
