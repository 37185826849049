<div class="flex justify-between items-center">
  <i *ngIf="!data.hideIcon" class="ib-snackbar__icon material-icons">{{
    icons[data.type]
  }}</i>

  <ng-container *ngIf="data.tmpl; else textTmpl">
    <ng-container [ngTemplateOutlet]="data.tmpl"></ng-container>
  </ng-container>

  <ng-template #textTmpl>
    <div class="grow">
      <p *ngIf="data.msg" class="ib-snackbar__msg">{{ data.msg }}</p>
      <p *ngIf="data.msgLocaliKey" class="ib-snackbar__msg">
        {{ data.msgLocaliKey | transloco }}
      </p>
    </div>
  </ng-template>

  <button
    mat-button
    type="button"
    class="flex ib-snackbar__close ib-btn ib-btn-icon ib-btn-icon-secondary"
    (click)="close()"
  >
    <i class="material-icons">close</i>
  </button>
</div>
