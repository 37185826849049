import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UiTouchscreenService {
  private isTouchScr: boolean;

  constructor() {
    this.initDevice();
  }

  get isTouchScreen(): boolean {
    return this.isTouchScr;
  }

  private initDevice(): void {
    this.isTouchScr =
      'ontouchstart' in window ||
      navigator?.maxTouchPoints > 0 ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (navigator as any)?.msMaxTouchPoints > 0;
  }
}
