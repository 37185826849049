export enum Format {
	INTEGER = 'integer',
	DECIMAL = 'decimal',
	TEXT = 'text',
	DATE = 'date',
	PERCENTAGE = 'percentage',
	YESNO = 'Yes/No',
	PHONE = 'phone number',
	SORT_CODE = 'sort code',
	IBAN = 'iban'
}

export enum SubFormat {
	SELECT = 'select'
}

export enum FieldType {
	INPUT = 'input',
	ALGORITHM = 'algorithm',
}

export enum Products {
	A_ETR = 'a-ETR',
	B_ETR = 'b-ETR',
	C_ETR = 'c-ETR',
	D_ETR = 'd-ETR',
	F_ETR = 'f-ETR',
	G_ETR = 'g-ETR',
	H_ETR = 'h-ETR',
	IbETR = 'Investabill - ETR',
}

export enum InvestorTransactionType {
	RPAReceipt = 'RPA Receipt',
	Redemption = 'Redemption',
	Adjustment = 'Adjustment'
}

export enum InvestmentType {
	Demand = 'Demand',
	Term = 'Term',
	Fixed = 'Fixed',
}

export enum JournalLineType {
	DEBIT = 'Debit',
	CREDIT = 'Credit',
}

export const F_PREVIOUS = 'Previous '; // To mark previous row of data
