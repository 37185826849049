import { Component, HostBinding, OnInit } from '@angular/core';

import { AuthFacadeService } from '@ib-core/services/auth-facade.service';
import { UiBreakpointsService } from '@ib-core/services/ui-breakpoints.service';
import { UiTouchscreenService } from '@ib-core/services/ui-touchscreen.service';

// todo - think about get APP VERSION from package.json -
// https://stackoverflow.com/questions/34907682/how-to-display-the-app-version-in-angular
// solution don't work

@Component({
  // !!!! = only app-root
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @HostBinding('class') get classes(): string {
    return [
      this.tsS.isTouchScreen ? 'ib-touchscreen' : '',
      this.bsS.isMobile ? 'ib-mobile-layout' : '',
    ].join(' ');
  }

  constructor(
    private tsS: UiTouchscreenService,
    private bsS: UiBreakpointsService,
    private authFacadeS: AuthFacadeService,
  ) {}

  ngOnInit(): void {
    if (this.authFacadeS.accessToken && this.authFacadeS.refreshToken) {
      this.authFacadeS.getMe().subscribe();
    }
  }
}
