import { envNames } from 'credebt-shared';

import { APP_VERSION } from './app-version';
import { IEnvironment } from './environment.intf';

export const environment: IEnvironment = {
  production: true,
  apiUrl: '',
  appVersion: APP_VERSION,
  envName: envNames.STAG,
};
