import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode, NgModule } from '@angular/core';

import { LANGUAGE } from '@ib-enums/languages.enum';
import { LOCAL_STORAGE_KEYS } from '@ib-enums/local-storage-keys.enum';
import {
  provideTranslocoConfig,
  provideTranslocoLoader,
  Translation,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
  TranslocoPipe,
  TranslocoService,
} from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTranslation(lang: string): Observable<Record<string, any>> {
    const ts = new Date().getTime();
    return this.http.get<Translation>(`/assets/i18n/${lang}.json?ts=${ts}`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    TranslocoPipe,
    TranslocoService,
    provideTranslocoConfig(
      translocoConfig({
        availableLangs: Object.values(LANGUAGE),
        defaultLang: ((): string => {
          const lang = localStorage.getItem(LOCAL_STORAGE_KEYS.LANG)
            ? localStorage.getItem(LOCAL_STORAGE_KEYS.LANG).replace(/"/g, '')
            : LANGUAGE.EN;
          return lang;
        })(),
        // Remove this option if your application
        // doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      }),
    ),
    provideTranslocoLoader(TranslocoHttpLoader),
  ],
})
export class TranslocoRootModule {}
