import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { IB_ROUTES } from '@ib-enums/routes.enum';

type Tree = Partial<
  Record<
    IB_ROUTES,
    Partial<
      Record<
        IB_ROUTES,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Partial<Record<IB_ROUTES, Partial<Record<IB_ROUTES, Partial<any>>>>>
      >
    >
  >
>;

@Injectable({ providedIn: 'root' })
export class NavigationService {
  // todo - try to import here main app routing and create from it tree
  // todo replace all child route modules to main file for use it

  static tree: Tree = {
    [IB_ROUTES.BASE]: {
      [IB_ROUTES.AUTHENTICATED]: {
        [IB_ROUTES.DASHBOARD]: {},
        [IB_ROUTES.WITHDRAW]: {},
        [IB_ROUTES.ACTIVITY]: {},
        [IB_ROUTES.EMAIL_CONFIRMATION]: {},
      },
      [IB_ROUTES.NOT_AUTHENTICATED]: {
        [IB_ROUTES.SIGN_UP]: {},
        [IB_ROUTES.LOGIN]: {},
        [IB_ROUTES.FORGOT_PASSWORD]: {},
        [IB_ROUTES.RESET_PASSWORD]: {},
      },
      [IB_ROUTES.CHECK_REGISTR_EMAIL_LINK]: {},
      [IB_ROUTES.PAGE_NOT_FOUND]: {},
    },
  };

  static getRouteSegmentsByRoute(search: IB_ROUTES): Array<string> {
    const segments = [IB_ROUTES.BASE];
    const find = (parent: Tree, findRoute: IB_ROUTES): boolean => {
      const childRoutes = Object.keys(parent);
      if (childRoutes.length) {
        return childRoutes.some(childRoute => {
          if (childRoute === findRoute) {
            segments.push(childRoute);
            return true;
          } else {
            const res = find(parent[childRoute], findRoute);
            if (res) {
              segments.push(childRoute as IB_ROUTES);
              return true;
            } else {
              return false;
            }
          }
        });
      } else {
        return false;
      }
    };
    find(this.tree, search);
    const res = segments.reverse();
    res.pop();
    return res;
  }

  static getRouteUrl(route: IB_ROUTES): string {
    return NavigationService.getRouteSegmentsByRoute(route)
      .join('/')
      .substring(1);
  }

  constructor(private router: Router) {
    this.initScrollAfterNavigation();
  }

  private initScrollAfterNavigation(): void {
    const exeptions = [];
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd && exeptions.includes(ev.url)) {
        window.scrollTo(window.scrollX, 0);
      }
    });
  }
  // ========= GO TO ===============

  goToRoute(r: IB_ROUTES, options?: unknown): void {
    options;
    if (r === IB_ROUTES.AUTHENTICATED || r === IB_ROUTES.NOT_AUTHENTICATED) {
      throw new Error(
        'Forbidden to redirect by navigation - will not file NavigationEnd event, These routes need only for wrap and for default load from browser url',
      );
    }
    this.router.navigate(NavigationService.getRouteSegmentsByRoute(r));
  }
}
