import { CommonModule } from '@angular/common';
import { Component, HostBinding, Inject } from '@angular/core';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import { ISnackbarData } from '@ib-shared/interfaces/snackbar-data.intf';
import { TranslocoRootModule } from '@ib-shared/transloco-root';
@Component({
  selector: 'ib-snackbar',
  templateUrl: './snackbar.component.html',
})
export class IbSnackbarComponent {
  public icons = {
    success: 'check_circle',
    error: 'error',
    info: 'info_outline',
    warning: 'warning_amber',
  };
  @HostBinding('class') get classes(): string {
    return ['ib-snackbar', 'ib-snackbar--' + this.data.type].join(' ');
  }

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarData,
    private _snackRef: MatSnackBarRef<any>,
  ) {}

  close(): void {
    this._snackRef.dismiss();
  }
}

@NgModule({
  declarations: [IbSnackbarComponent],
  imports: [CommonModule, MatProgressSpinnerModule, TranslocoRootModule],
  exports: [IbSnackbarComponent],
})
export class IbSnackbarModule {}
