import { ITailwindBreakpoints } from '@ib-shared/interfaces/breakpoints.intf';

// !!! screens MUST be equal to  const tailwind.config.js
export const TAILWIND_BREAKPOINTS: ITailwindBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};
