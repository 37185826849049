
export enum INVESTOR_FIELD_NAME {
	Account_Holder = "Account Holder",
	Bank_Account = "Bank Account",
	Bank_Name = "Bank Name",
	SWIFT_BIC = "SWIFT BIC",
	Sort_Code = "Sort Code",
	IBAN = "IBAN",

	Last_Name = "Last Name",
	First_Name = "First Name",
	Email = "Email",
	Mobile_Number = "Mobile Number",
	Phone_Number = "Phone Number",
	Date_Of_Birth = "Date Of Birth",
	// Home_Address_Line_1 = "Home Address Line 1", // problems here - we need fix migration data
	// Home_Address_Line_2 = "Home Address Line 2", // problems here - we need fix migration data

	Buy_Rate = "Buy Rate",
	Commission = "Commission",
	Date = "Date",
	Face_Value = "Face Value",
	Intermediary = "Intermediary",
	Intermediary_ID = "Intermediary ID",
	Investment_Type = "Investment Type",
	Investor_ID = "Investor ID",
	Processing_Commission = "Processing Commission",
	Product = "Product",
	Request = "Request",
	TransactionType = 'Transaction Type'
}
