import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import { TokenInterceptor } from '@ib-core/interceptors/access-token-interceptor';
import { AuthHttpService } from '@ib-core/services/auth-http.service';
import { AuthStateService } from '@ib-core/services/auth-state.service';
import { NavigationService } from '@ib-core/services/navigation.service';
import { MatSnackBarService } from '@ib-core/services/snackbar.service';
import { UiBreakpointsService } from '@ib-core/services/ui-breakpoints.service';
import { UiSidebarService } from '@ib-core/services/ui-sidebar.service';
import { UiTouchscreenService } from '@ib-core/services/ui-touchscreen.service';
import { AuthFacadeService } from '@ib-core-services/auth-facade.service';
import { LocalStorageService } from '@ib-core-services/local-storage.service';
import { TranslocoRootModule } from '@ib-shared/transloco-root';
import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app.component';
import {
  AppInitializerService,
  initializeApp,
} from './app-initializer.service';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    TranslocoRootModule,
  ],
  providers: [
    // core
    MatDialog,
    DecimalPipe,
    MatSnackBar,

    // app
    MatSnackBarService,
    AuthFacadeService,
    AuthStateService,
    AuthHttpService,
    NavigationService,
    LocalStorageService,
    AppInitializerService,
    UiSidebarService,
    UiTouchscreenService,
    UiBreakpointsService,

    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializerService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
