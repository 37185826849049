import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { AuthFacadeService } from '@ib-core/services/auth-facade.service';
import { AuthHttpService } from '@ib-core/services/auth-http.service';
import { LocalStorageService } from '@ib-core/services/local-storage.service';
import { MatSnackBarService } from '@ib-core/services/snackbar.service';
import { LOCAL_STORAGE_KEYS } from '@ib-enums/local-storage-keys.enum';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private accessTokenSubject$ = new BehaviorSubject<string | undefined>(
    undefined,
  );
  constructor(private injector: Injector) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const authFacadeS: AuthFacadeService = this.injector.get(AuthFacadeService);
    const authHttpS: AuthHttpService = this.injector.get(AuthHttpService);
    const snackbarS: MatSnackBarService = this.injector.get(MatSnackBarService);
    const lsS: LocalStorageService = this.injector.get(LocalStorageService);
    const at = lsS.get(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    if (at) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${at}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (request.url === authHttpS.REFRESH_TOKEN_URL) {
            authFacadeS.logout();
            return throwError(() => error);
          } else {
            return this.handle401Error(
              request,
              next,
              authFacadeS,
              authHttpS,
              error,
            );
          }
        } else {
          snackbarS.handleHttpError(error);
        }
        return throwError(() => error);
      }),
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    authFacadeS: AuthFacadeService,
    authHttpS: AuthHttpService,
    error: HttpErrorResponse,
  ): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject$.next(undefined);
      const refreshToken = authFacadeS.refreshToken;
      if (!refreshToken) {
        authFacadeS.logout();
        return throwError(() => error);
      } else {
        return authHttpS.refreshToken(refreshToken).pipe(
          catchError(error => {
            this.isRefreshing = false;
            authFacadeS.logout();
            return throwError(() => error);
          }),
          switchMap(data => {
            this.isRefreshing = false;
            authFacadeS.setRefreshToken(data.refresh);
            authFacadeS.setAccessToken(data.access);
            this.accessTokenSubject$.next(data.access);
            return next.handle(
              request.clone({
                setHeaders: {
                  Authorization: `Bearer ${data?.access}`,
                },
              }),
            );
          }),
        );
      }
    }

    return this.accessTokenSubject$.pipe(
      filter(accessToken => accessToken !== undefined),
      take(1),
      switchMap(accessToken => {
        return next.handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
            },
          }),
        );
      }),
    );
  }
}
