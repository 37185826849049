import { Injectable } from '@angular/core';

import { BehaviorSubject, map, Observable } from 'rxjs';

import { UiBreakpointsService } from './ui-breakpoints.service';

@Injectable({
  providedIn: 'root',
})
export class UiSidebarService {
  public isSidebarExpanded$ = new BehaviorSubject<boolean>(false);
  private sidebarCollapseBp = this.bpS.breakpoints.lg;
  private sidebarMobileBp = this.bpS.breakpoints.sm;

  get isMobileView(): boolean {
    return window.innerWidth < this.sidebarMobileBp;
  }
  constructor(private bpS: UiBreakpointsService) {
    this.initWinResize();
  }

  private initWinResize(): void {
    this.initSidebarOptions();
    // fromEvent(window, 'resize')
    //   .pipe(debounceTime(50))
    //   .subscribe(() => {
    //     this.initSidebarOptions();
    //   });
  }

  private initSidebarOptions(): void {
    this.isSidebarExpanded$.next(window.innerWidth >= this.sidebarCollapseBp);
  }

  selectIsSidebarExpanded(): Observable<{ v: boolean }> {
    return this.isSidebarExpanded$.asObservable().pipe(map(r => ({ v: r })));
  }

  setSidebarState(isOpen: boolean): void {
    this.isSidebarExpanded$.next(isOpen);
  }

  toggleSidebar(): void {
    this.isSidebarExpanded$.next(!this.isSidebarExpanded$.value);
  }
}
