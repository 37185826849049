import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { AuthFacadeService } from '@ib-core-services/auth-facade.service';
import { NavigationService } from '@ib-core-services/navigation.service';
import { IB_ROUTES } from '@ib-enums/routes.enum';

export const AuthGuard: CanActivateFn = () => {
  const authFacadeS = inject(AuthFacadeService);
  const navS = inject(NavigationService);
  if (authFacadeS.authUser) {
    return true;
  } else {
    navS.goToRoute(IB_ROUTES.LOGIN);
    return false;
  }
};
