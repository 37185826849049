import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { IB_ROUTES } from '@ib-enums/routes.enum';

import { AuthGuard } from './core/guards/auth.guard';
import { NotAuthGuard } from './core/guards/not-auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: IB_ROUTES.AUTHENTICATED },
  {
    path: IB_ROUTES.AUTHENTICATED,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/authenticated/authenticated.module').then(
        m => m.AuthenticatedModule,
      ),
  },
  {
    path: IB_ROUTES.NOT_AUTHENTICATED,
    canActivate: [NotAuthGuard],
    loadChildren: () =>
      import('./views/not-auth/not-auth.module').then(m => m.NotAuthModule),
  },
  {
    path: IB_ROUTES.CHECK_REGISTR_EMAIL_LINK,
    loadChildren: () =>
      import(
        './views/check-registr-email-link/check-registr-email-link.module'
      ).then(m => m.CheckRegistrEmailLinkModule),
  },
  {
    path: IB_ROUTES.PAGE_NOT_FOUND,
    loadChildren: () =>
      import('./views/page-not-found/page-not-found.module').then(
        m => m.PageNotFoundModule,
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./views/page-not-found/page-not-found.module').then(
        m => m.PageNotFoundModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
