export const LOCAL_STORAGE_KEYS_CONST_PREFIX = 'ib-const__';
export const LOCAL_STORAGE_KEYS_PREFIX = 'ib__';
export enum LOCAL_STORAGE_KEYS {
  // not cleaned after change version (ib-const__)
  APP_VERSION = 'ib-const__app-version',
  LANG = 'ib-const__lang',
  ACCESS_TOKEN = 'ib-const__at',
  REFRESH_TOKEN = 'ib-const__rt',

  // cleaned after change version (ib__)
  AUTH_USER = 'ib__auth-user',
  SIGN_UP_DATA = 'ib__sign-up-data',
}
