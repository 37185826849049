import { Injectable } from '@angular/core';

import { TAILWIND_BREAKPOINTS } from '@ib-core/config/breakpoints.config';
import {
  ITailwindBreakpoints,
  IUiBreakpointsState,
} from '@ib-shared/interfaces/breakpoints.intf';
import { BehaviorSubject, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiBreakpointsService {
  public readonly breakpoints: ITailwindBreakpoints = TAILWIND_BREAKPOINTS;
  private breakpointsUiState$ = new BehaviorSubject<IUiBreakpointsState | null>(
    null,
  );

  get isSm(): boolean {
    return this.breakpointsUiState$.value.isSm;
  }
  get isMd(): boolean {
    return this.breakpointsUiState$.value.isMd;
  }
  get isLg(): boolean {
    return this.breakpointsUiState$.value.isLg;
  }
  get isXl(): boolean {
    return this.breakpointsUiState$.value.isXl;
  }
  get is2xl(): boolean {
    return this.breakpointsUiState$.value.is2xl;
  }
  get isLessMd(): boolean {
    return this.breakpointsUiState$.value.isLessMd;
  }
  get isMobile(): boolean {
    return this.isLessMd;
  }

  constructor() {
    this.initBreakpoints();
  }

  private initBreakpoints(): void {
    this.initValues(window.innerWidth);
    fromEvent(window, 'resize')
      // .pipe(debounceTime(10))
      .subscribe(() => {
        this.initValues(window.innerWidth);
      });
  }

  private initValues(winW: number): void {
    this.breakpointsUiState$.next({
      isSm: winW < this.breakpoints.sm,
      isLessMd: winW < this.breakpoints.md,
      isMd: winW < this.breakpoints.md,
      isLg: winW < this.breakpoints.lg,
      isXl: winW < this.breakpoints.xl,
      is2xl: winW < this.breakpoints['2xl'],
      values: this.breakpoints,
    });
  }
}
