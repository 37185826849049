import { INVESTOR_FIELD_NAME } from "../enums/investor-field-types.enum";

const INVESTOR_FIELDS_MAP_TO_DB_FIELDS = {
	[INVESTOR_FIELD_NAME.Account_Holder]: 'bankHolderName',
	[INVESTOR_FIELD_NAME.Bank_Account]: 'bankAccount',
	[INVESTOR_FIELD_NAME.Bank_Name]: 'bankName',
	[INVESTOR_FIELD_NAME.SWIFT_BIC]: 'bankSwiftBic',
	[INVESTOR_FIELD_NAME.Sort_Code]: 'bankSortCode',
	[INVESTOR_FIELD_NAME.IBAN]: 'bankIban',

	[INVESTOR_FIELD_NAME.Last_Name]: 'lastName',
	[INVESTOR_FIELD_NAME.First_Name]: 'firstName',
	[INVESTOR_FIELD_NAME.Email]: 'email',
	[INVESTOR_FIELD_NAME.Mobile_Number]: 'mobilePhone',
	[INVESTOR_FIELD_NAME.Phone_Number]: 'phone',
	[INVESTOR_FIELD_NAME.Date_Of_Birth]: 'dob',
	// [INVESTOR_FIELDS.Home_Address_Line_1]: '',
	// [INVESTOR_FIELDS.Home_Address_Line_2]: '',
	[INVESTOR_FIELD_NAME.Buy_Rate]: "buyRate",
	[INVESTOR_FIELD_NAME.Commission]: "commission",
	[INVESTOR_FIELD_NAME.Date]: "date",
	[INVESTOR_FIELD_NAME.Face_Value]: "faceValue",
	[INVESTOR_FIELD_NAME.Intermediary]: "intermediary",
	[INVESTOR_FIELD_NAME.Intermediary_ID]: "intermediaryId",
	[INVESTOR_FIELD_NAME.Investment_Type] :"investmentType",
	[INVESTOR_FIELD_NAME.Investor_ID]: "investor",
	[INVESTOR_FIELD_NAME.Processing_Commission]: "Processing Commission",
	[INVESTOR_FIELD_NAME.Product]: "product",
	[INVESTOR_FIELD_NAME.Request]: "request",
	[INVESTOR_FIELD_NAME.TransactionType]: 'transactionType'
}

export function getDbFieldName(v: INVESTOR_FIELD_NAME): string {
	return INVESTOR_FIELDS_MAP_TO_DB_FIELDS[v];
}


