import { Injectable } from '@angular/core';

import { LOCAL_STORAGE_KEYS } from '@ib-enums/local-storage-keys.enum';
import { environment } from '@ib-env/environment';

import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  private readonly v = environment.appVersion;
  constructor(private localStorageS: LocalStorageService) {}
  get version(): string {
    return this.v;
  }

  checkVersion(): void {
    const oldV = this.localStorageS.get(LOCAL_STORAGE_KEYS.APP_VERSION);
    const newV = this.version;
    if (oldV !== newV) {
      this.localStorageS.set(LOCAL_STORAGE_KEYS.APP_VERSION, newV);
      this.localStorageS.clearAfterChangeVersion();
    }
  }
}
